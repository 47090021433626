import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import IconFacebook from '../icons/facebook.svg'
import IconInstagram from '../icons/instagram.svg'
import IconYelp from '../icons/yelp.svg'
import IconTwitter from '../icons/twitter.svg'
import IconPinterest from '../icons/pinterest.svg'

const Footer = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <footer>
      <div className="container text-gray-800 mb-12">
        <div className="flex flex-col items-center lg:flex-row lg:justify-between relative py-12 px-4 lg:px-6 lg:text-xs border-l-2 border-r-2 border-gray-800">
          <svg
            className="h-12 mb-6 lg:mb-0 flex-shrink-0 lg:mr-10"
            viewBox="0 0 174 42"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>{site.siteMetadata.title}</title>
            <path d="M.24.8h40.78v40.78H.24z" fill="#312F51" />
            <path
              d="M11.3 9.79H8.96v22.87h6.02v-2.43H11.3zM25.57 15.02L24.41 9.4l-1.16 5.62-3.64 17.64h2.32l1.01-4.91h2.93l1.01 4.91h2.32l-3.63-17.64zm-2.13 10.3l.97-4.69.97 4.69h-1.94zm1.93 0h-8.28v2.43h8.78l-.5-2.43zM18.52 31.49a1.24 1.24 0 10-1.24 1.24c.69.01 1.24-.55 1.24-1.24zM32.64 31.49a1.24 1.24 0 10-1.24 1.24c.68.01 1.24-.55 1.24-1.24z"
              fill="#FFF"
            />
            <path
              d="M79.18 11.53h-2.85v20.31H74V11.53h-2.83V9.06h8.01zM93.64 31.84h-6.43V9.06h6.04v2.64h-3.69v11.64h3.69v2.44h-3.69v3.62h4.08zM103.07 31.84l-2.06-7.29-2.04 7.29h-2.02l2.93-11.16-3.1-11.62h2.35l2 6.95 1.9-6.95h1.93l-2.75 10.95 3.19 11.83zM115.29 11.6c-1.8.97-3.19 2.33-4.15 4.07a9.778 9.778 0 00-1.23 4.97c.01 1.69.42 3.25 1.21 4.67.9 1.66 2.27 3.03 4.1 4.11v2.5c-2.04-.6-3.75-1.78-5.12-3.53-1.25-1.61-2.05-3.47-2.39-5.57-.11-.68-.17-1.38-.17-2.09 0-1.88.37-3.66 1.11-5.35.82-1.89 2.02-3.46 3.62-4.71.82-.63 1.83-1.17 3.02-1.62v2.55zM127.29 31.84h-2.33v-6.06h-3.83v6.06h-2.33V9.06h2.33v14.28h3.83V9.06h2.33zM151.73 31.94h-2.31v-9.33l-3.66-5.83v15.16h-2.34V8.89l6 9.29V9.16h2.31zM163.16 32.03c-2.62-1-4.63-2.48-6.02-4.43-1.39-1.96-2.08-4.28-2.08-6.95 0-1.9.39-3.69 1.17-5.37.9-1.94 2.24-3.54 4.02-4.79.71-.49 1.65-.92 2.81-1.3v2.41c-1.74.91-3.12 2.14-4.15 3.71a9.203 9.203 0 00-1.54 5.15c0 1.3.24 2.55.73 3.75.6 1.44 1.49 2.63 2.67 3.57v-5.01h-1.65v-2.41h4.04v11.67zM173.7 31.84h-6.43V9.06h6.03v2.64h-3.68v11.64h3.68v2.44h-3.68v3.62h4.08zM68.58 19.17c0-1.13-.18-2.24-.54-3.33a10.76 10.76 0 00-2.87-4.5c-1.24-1.13-2.6-1.91-4.1-2.33V31.9h2.33v-3.74c.1-.04.19-.09.27-.14l2.15 3.88h2.58l-2.77-5.29c1.97-2.13 2.95-4.61 2.95-7.44zm-5.18 5.97V13.02c.92.8 1.64 1.83 2.17 3.08.47 1.13.71 2.22.71 3.27 0 .2-.01.4-.02.57-.1.95-.38 1.87-.86 2.76-.5.98-1.17 1.8-2 2.44zM54.17 14.27l-1.15-5.6-1.15 5.6-3.62 17.57h2.31l1.01-4.89h2.92l1.01 4.89h2.31l-3.64-17.57zm-2.11 10.26l.96-4.67.96 4.67h-1.92zM136.58 14.27l-1.15-5.6-1.15 5.6-3.62 17.57h2.31l1.01-4.89h2.92l1.01 4.89h2.31l-3.64-17.57zm-2.12 10.26l.96-4.67.96 4.67h-1.92z"
              fill="#312F51"
            />
          </svg>

          <ul className="mb-10 lg:mb-0 lg:order-first lg:mr-10">
            {[
              {
                route: `/about`,
                title: `About`,
              },
              {
                route: `/corporate`,
                title: `Corporate`,
              },
              {
                route: `/quote`,
                title: `Quote`,
              },
            ].map(link => (
              <li className="text-center lg:text-left lg:my-3" key={link.title}>
                <Link
                  className="inline-block py-2 lg:py-0 underline text-gray-900 text-center uppercase tracking-wider font-medium"
                  to={link.route}
                >
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>

          <section
            aria-label="contact information"
            className="w-full align-self-start mb-12 lg:mb-0 lg:ml-auto lg:mr-10 max-w-xs"
          >
            <h2 className="mb-4 text-center text-lg lg:text-sm lg:text-left">
              Stop By
            </h2>
            <ul>
              <li className="border-t border-gray-900 text-gray-800 p-2 lg:px-0">
                922 Santa Monica Blvd.
                <br />
                Santa Monica, CA 90401
              </li>
              <li className="border-t border-gray-900 text-gray-800 p-2 lg:px-0">
                <a
                  className="text-orange-700"
                  href="https://www.google.com/maps/place/L.A.+Art+Exchange/@34.0206405,-118.4919085,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2bb47f086c019:0xf43ea14a7762f71a!8m2!3d34.0206361!4d-118.4897198"
                >
                  Google Map
                </a>
              </li>
              <li className="border-t border-gray-900 text-gray-800 p-2 lg:px-0">
                <a href="tel:3108286866">310-828-6866</a>
              </li>
              <li className="border-t border-gray-900 text-gray-800 p-2 lg:px-0">
                <a href="mailto:gallery@laartexchange.com">
                  gallery@laartexchange.com
                </a>
              </li>
            </ul>
          </section>

          <div className="w-full max-w-xs lg:ml-auto">
            <section
              aria-label="hours of operation"
              className="w-full mb-12 lg:mb-0"
            >
              <h2 className="mb-4 text-center text-gray-900 text-lg lg:text-sm lg:text-left">
                We&apos;re Available
              </h2>
              <ul className="border-b border-t my-3 text-gray-800 border-gray-800">
                {[
                  {
                    day: `Monday - Friday`,
                    time: `9:30am - 6pm`,
                  },
                  {
                    day: `Saturday`,
                    time: `10:00am - 5pm`,
                  },
                  {
                    day: `Sunday`,
                    time: `Closed`,
                  },
                ].map(({ day, time }, index) => (
                  <li className="p-2 lg:px-0" key={index}>
                    <dl className="flex items-center">
                      <dt className="font-bold mr-2">{day}:</dt>
                      <dd>{time}</dd>
                    </dl>
                  </li>
                ))}
              </ul>
            </section>

            <ul className="flex items-center justify-around lg:justify-start lg:mt-2 lg:-ml-2">
              {[
                {
                  url: `https://www.facebook.com/LAARTEXCHANGE/`,
                  component: <IconFacebook />,
                  title: `Facebook`,
                },
                {
                  url: `https://www.instagram.com/laartexchange/`,
                  component: <IconInstagram />,
                  title: `Instagram`,
                },
                {
                  url: `https://www.yelp.com/biz/la-art-exchange-santa-monica`,
                  component: <IconYelp />,
                  title: `Yelp`,
                },
                {
                  url: `https://twitter.com/laartexch`,
                  component: <IconTwitter />,
                  title: `Twitter`,
                },
                {
                  url: `https://www.pinterest.com/art3715/`,
                  component: <IconPinterest />,
                  title: `Pinterest`,
                },
              ].map(({ url, component, title }) => (
                <li className="px-3" key={title.toLowerCase()}>
                  <a aria-label={`external link to ${title} page`} href={url}>
                    {component}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {[
            `left-0 top-0`,
            `left-0 bottom-0`,
            `right-0 top-0`,
            `right-0 bottom-0`,
          ].map(item => (
            <div
              className={`${item} absolute w-10 bg-gray-800`}
              key={item.replace(/ /g, ``)}
              role="presentation"
              style={{ height: `2px` }}
            ></div>
          ))}
        </div>
      </div>

      <div className="border-t border-gray-500 py-4">
        <div className="container text-center text-xs font-semibold">
          &copy; {new Date().getFullYear()} LA Art Exchange
        </div>
      </div>
    </footer>
  )
}

export default Footer
