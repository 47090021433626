import PropTypes from 'prop-types'
import React from 'react'

import Header from './header'
import Footer from './footer'

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <div className="flex flex-col flex-1 md:justify-center py-4 md:py-8 lg:pb-20 w-full">
        {children}
      </div>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
